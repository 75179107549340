
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import AOS from 'aos'
import 'aos/dist/aos.css';
import AppearTransition from '@/components/utilities/transitions/AppearTransition.vue';

@Component({
    components: {
        SvgIcon,
        AppearTransition
    },
})
export default class InfoSide extends Vue {
    @Prop() title!: string;
    @Prop() subtitle?: string;
    @Prop() image!: string;
    @Prop() darkMode!: boolean;
    @Prop() right?: boolean;
    @Prop() button?: {
        name: string;
        text?: string;
        level: number;
        redirect?: string;
    };

    // mounted(){
    //     AOS.refresh();
    // }
    

    get isRightSide(){
        if (this.right) return true
        else return false
    }

}
