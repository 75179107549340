
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { required, minLength, sameAs} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import InfoSide from '@/components/InfoSide.vue';
import { auth} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { User } from '@/modules/auth/interfaces/user.interface';
import { showToast } from '@/utils/global-functions';
import Loader from '@/components/utilities/Loader.vue';

@Component({
    components: {
        SvgIcon,
        InfoSide,
        Loader
    },
    mixins: [validationMixin],
    validations: {
        last_password:{
                required
        },
        new_password: {
                required,
                minLength: minLength(8),
                containsUppercase: function (value) {
                    return /[A-Z]/.test(value);
                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value);
                },
         },
         repeatPassword: {
                required,
                minLength: minLength(8),
                containsUppercase: function (value) { 
                    return /[A-Z]/.test(value);
                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value);
                },
                sameAs: sameAs('new_password')
          },
    }
})
export default class RecoverPassword extends Vue {
    new_password: string ='';
    last_password: string ='';
    repeatPassword: string ='';
    $refs: any = {};
    showPassword = false;
    showNewPassword = false;
    showRepeatNewPassword = false;

    async submit(){
        this.$v.$touch();
        if (!this.$v.$invalid){
            this.$refs.loader.showLoader();
            let response = await this.changePassword({email_user: this.userData.email_user, new_password: this.new_password, last_password: this.last_password})
            this.$refs.loader.hideLoader();
            if (response.error)
                showToast(response.msg,'error')
            else {
                showToast(response.msg,'success')
                this.$router.go(-1)
            }
        }
    }

    @auth.Action(AuthMethods.actions.CHANGE_PASSWORD)
        changePassword!: (data:{email_user: string, new_password: string, last_password: string}) => Promise<{error: boolean, msg: string}>;
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
        userData: User;
}
